import LexoRankBetween from './LexoRankBetween'
import LexoRankNext from './LexoRankNext'

export default class LexoRank {
  getRankBetween (prev = '', next = '') {
    return new LexoRankBetween(this, prev, next)
  }

  getNextRank (prev = 'ab') {
    return new LexoRankNext(this, prev)
  }
}
