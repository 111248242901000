export default class DateTime {
  /**
   * Create a new instance of DateTime.
   *
   * @param  {Adapter}
   */
  constructor (adapter) {
    this.adapter = adapter
  }

  /**
   * Format a date string like '1986-09-04'.
   *
   * @param  {String}
   * @param  {Boolean}
   * @return {String}
   */
  isoDate (date, utc = false) {
    return this.adapter.isoDate(this.resolveDate(date, utc), utc)
  }

  /**
   * Format a date string like '1986-09-04T08:30:25Z'.
   *
   * @param  {String}
   * @return {String}
   */
  isoDateTime (date, utc = false) {
    return this.adapter.isoDateTime(this.resolveDate(date, utc), utc)
  }

  /**
   * Calculates the number of days from today to the later date. If the dates are the same then the
   * days until will be 0. If the later date is actually earlier than today, then the number of days
   * will be negative.
   *
   * @param  {String}
   * @param  {Boolean}
   * @return {Number}
   */
  numDaysUntil (date, utc = false) {
    return this.adapter.numDaysBetween(
      this.adapter.isoDate(this.adapter.now(utc), utc),
      this.resolveDate(date, utc)
    )
  }

  /**
   * Returns 'Today', 'Tomorrow', 'Yesterday', or a calendar date depending on the given date's
   * relation to now.
   *
   * @param  {String}
   * @param  {Boolean}
   * @return {String}
   */
  relativeCalendarDate (date, utc = false) {
    return this.adapter.relativeCalendarDate(this.resolveDate(date, utc))
  }

  /**
   * Formats a date string like '9/4/1986'.
   *
   * @param  {String}
   * @param  {Boolean}
   * @return {String}
   */
  shortDate (date, utc = false) {
    return this.adapter.shortDate(this.resolveDate(date, utc))
  }

  /**
   * Ensure the date is a non-empty string. If it is empty or not a string, then return a date-time
   * string for right now, adjusted for UTC as specified.
   *
   * @param  {String}
   * @param  {Boolean}
   * @return {String}
   */
  resolveDate (date, utc) {
    if (typeof date !== 'string' || date === '') {
      return this.adapter.now(utc)
    }

    return date
  }
}
