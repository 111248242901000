import container from '@/container'
import { prepChecklistItem } from '../helpers'

export default async function ({ commit, getters }, checklistItem) {
  const oldItem = getters.checklistItemById(checklistItem.id) || {}

  const newItem = prepChecklistItem(oldItem, checklistItem, true)

  return new Promise((resolve) => {
    setTimeout(() => {
      commit('updateChecklistItem', newItem)
      resolve(newItem)
    }, container.make('config').saveDelay)
  })

  // return newItem
}
