export default function deleteChecklistItem (state, checklistItemId) {
  const ids = new Set()
  let addIds = [checklistItemId]

  while (addIds.length !== 0) {
    ids.add(...addIds)
    addIds = []

    state.checklistItems.forEach(item => {
      if (!ids.has(item.id) && ids.has(item.parentId)) {
        addIds.push(item.id)
      }
    })
  }

  state.checklistItems = state.checklistItems.filter(item => !ids.has(item.id))
}
