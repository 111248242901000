import container from '@/container'

export default async function deleteTask ({ commit }, taskId) {
  return new Promise((resolve) => {
    setTimeout(() => {
      commit('deleteTask', taskId)
      resolve(taskId)
    }, container.make('config').saveDelay)
  })

  // return taskId
}
