<template>
  <transition :name="deleteTransition">
    <div
      @click="handleClick"
      :class="{ 'task-card': true, 'task-complete': isComplete }"
      :data-task-id="task.id"
      ref="card"
    >
      <b-overlay :show="isProcessing" rounded="sm">
        <b-card :border-variant="borderVariant" :class="{ 'call-out-animation': highlightNew }">
          <b-card-text>
            <b-form-checkbox @change="changeTaskStatus" :checked="isComplete" class="ignore-card-click" />
            <div class="task-title-container">
              <p class="task-title">{{task.title}}</p>
              <p v-if="isCarryOver" class="task-carried-over-text">Carried over for {{daysCarriedOver}} day{{daysCarriedOver !== 1 ? 's' : ''}}</p>
            </div>
            <b-button @click="removeTask" class="delete-btn ignore-card-click" variant="link">
              <fa-icon icon="trash-alt" />
            </b-button>
          </b-card-text>

          <!-- BOTTOM ICONS -->
          <b-card-text v-if="hasMetaData" class="meta-data">
            <span v-if="hasDescription">
              <fa-icon fixed-width icon="align-left" />
            </span>
            <span v-if="hasDueDate" :class="dueDateClass">
              <fa-icon :icon="['far', 'calendar-alt']" class="mr-1" fixed-width />
              <small>{{dueDate}}</small>
            </span>
            <span v-if="hasChecklists" :class="checklistsClass">
              <fa-icon icon="tasks" class="mr-1" fixed-width />
              <small>{{numCompletedChecklistItems}}/{{numChecklistItems}}</small>
            </span>
          </b-card-text>
          <!-- /BOTTOM ICONS -->

        </b-card>
      </b-overlay>
      <b-avatar v-if="isCarryOver" class="days-carried-over-badge" size="2em" :variant="carriedOverStatus">{{daysCarriedOver}}</b-avatar>
    </div>
  </transition>
</template>

<script>
import container from '@/container'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TaskCard',
  props: {
    currentDate: {
      required: true,
      type: String
    },
    isCarryOver: {
      default: false,
      required: false,
      type: Boolean
    },
    task: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      applyDeleteTransition: false,
      isProcessing: false,
      highlightNew: false
    }
  },
  computed: {
    ...mapGetters([
      'highlightedTaskId'
    ]),
    borderVariant () {
      return this.dueDateClassModifier || null
    },
    carriedOverStatus () {
      switch (this.daysCarriedOver) {
        case 1:
          return 'success'
        case 2:
          return 'warning'
        default:
          return 'danger'
      }
    },
    checklistsClass () {
      return this.numChecklistItems === this.numCompletedChecklistItems ? 'text-success' : ''
    },
    daysCarriedOver () {
      const start = moment(this.task.startedOn)
      const end = moment(this.currentDate)
      return parseInt(end.diff(start, 'days'), 10)
    },
    daysUntilDue () {
      if (this.hasDueDate) {
        return container.make('dateTime').numDaysUntil(this.dueDate)
      }

      return null
    },
    deleteTransition () {
      return this.applyDeleteTransition ? 'delete-task-animation' : ''
    },
    dueDate () {
      return this.hasDueDate
        ? container.make('dateTime').shortDate(this.task.dueDate)
        : ''
    },
    dueDateClass () {
      return this.dueDateClassModifier
        ? `text-${this.dueDateClassModifier}`
        : null
    },
    dueDateClassModifier () {
      switch (true) {
        case this.daysUntilDue === 1:
        case this.daysUntilDue === 0:
          return 'warning'
        case this.daysUntilDue < 0:
          return 'danger'
        default:
          return null
      }
    },
    hasChecklists () {
      return !!this.task.checklists?.length
    },
    hasDescription () {
      return !!this.task.description
    },
    hasDueDate () {
      return !!this.task.dueDate
    },
    hasMetaData () {
      return this.hasDescription || this.hasDueDate || this.hasChecklists
    },
    isComplete () {
      return this.task.completedOn !== null
    },
    numChecklistItems () {
      if (this.hasChecklists) {
        const walk = (total, item) => {
          let newTotal = total + 1

          if (item.items.length) {
            newTotal = newTotal + item.items.reduce(walk, 0)
          }

          return newTotal
        }

        return this.task.checklists.reduce((total, checklist) => {
          return total + checklist.items.reduce(walk, 0)
        }, 0)
      }

      return 0
    },
    numCompletedChecklistItems () {
      if (this.hasChecklists) {
        const walk = (total, item) => {
          let newTotal = total

          if (item.completedOn) {
            newTotal = newTotal + 1
          }

          if (item.items.length) {
            newTotal = newTotal + item.items.reduce(walk, 0)
          }

          return newTotal
        }

        return this.task.checklists.reduce((total, checklist) => {
          return total + checklist.items.reduce(walk, 0)
        }, 0)
      }

      return 0
    }
  },
  watch: {
    highlightedTaskId (newValue, oldValue) {
      if (newValue && newValue !== oldValue && newValue === this.task.id) {
        // Set observer with callback for when element comes into view
        const obs = new IntersectionObserver((entries) => {
          const entry = entries[0]
          if (entry.isIntersecting && this.highlightedTaskId === this.task.id) {
            this.highlightNew = true

            // Remove the highlight when animation ends
            setTimeout(() => this.removeHighlightFromTask(this.task.id), 1500)
          }
        })

        // Observe our root element
        obs.observe(this.$el)

        // Scroll to element (accounting for navbar being sticky)
        const yOffset = -1 * (56 + 16)
        const y = this.$el.getBoundingClientRect().top + window.pageYOffset + yOffset

        window.scrollTo({ top: y, behavior: 'smooth' })
      } else {
        this.highlightNew = false
      }
    }
  },
  methods: {
    ...mapActions([
      'completeTask',
      'deleteTask',
      'removeHighlightFromTask',
      'uncompleteTask'
    ]),
    handleClick (event) {
      const checkIgnore = (element) => {
        if (element.classList.contains('ignore-card-click')) {
          return true
        } else if (element === this.$refs.card) {
          return false
        }

        return checkIgnore(element.parentElement)
      }

      if (checkIgnore(event.target) === false) {
        this.$router.push(`/task/${this.task.id}`)
      }
    },
    changeTaskStatus () {
      // Prevent multiple submissions
      if (this.isProcessing) {
        return
      }

      this.isProcessing = true

      if (this.isComplete === false) {
        this.completeTask(this.task.id)
          .then(() => {
            this.isProcessing = false
          })
      } else {
        this.uncompleteTask(this.task.id)
          .then(() => {
            this.isProcessing = false
          })
      }
    },
    removeTask () {
      // Prevent multiple submissions
      if (this.isProcessing) {
        return
      }

      this.isProcessing = true
      this.applyDeleteTransition = true

      this.deleteTask(this.task.id)
        .then(() => {
          this.isProcessing = false
        })
        .catch(() => {
          this.isProcessing = false
          this.applyDeleteTransition = false
        })
    }
  }
}
</script>
