<template>
  <dd>
    <template v-if="!inEditMode">
      <div class="d-flex align-items-start">
        <span class="flex-fill">{{title}}</span>
        <b-button @click="inEditMode = true" class="ml-2" size="sm" type="button" variant="light">Edit</b-button>
      </div>
    </template>
    <template v-else>
      <b-overlay :show="isSaving">
        <b-form @submit.prevent="save">
          <div class="d-flex align-items-start">
            <b-form-input v-model="title" class="flex-fill" />
            <b-button class="ml-2" size="sm" type="submit" variant="primary">Save</b-button>
          </div>
        </b-form>
      </b-overlay>
    </template>
  </dd>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  mounted () {
    this.reset()
  },
  data () {
    return {
      inEditMode: false,
      isSaving: false,
      title: ''
    }
  },
  props: {
    task: {
      default: null,
      required: false,
      type: Object
    }
  },
  watch: {
    task () {
      this.reset()
    }
  },
  methods: {
    ...mapActions([
      'updateTask'
    ]),
    reset () {
      this.title = this.task?.title || ''
    },
    save () {
      this.isSaving = true

      const task = {
        id: this.task.id,
        title: this.title
      }

      this.updateTask(task)
        .then(() => {
          this.inEditMode = false
          this.isSaving = false
        })
    }
  }
}
</script>
