import container from '@/container'

export default function deleteChecklist ({ commit }, checklistId) {
  return new Promise((resolve) => {
    setTimeout(() => {
      commit('deleteChecklist', checklistId)
      resolve(checklistId)
    }, container.make('config').saveDelay)
  })

  // return checklistId
}
