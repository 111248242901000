import Adapter from './Adapter'

export default class MomentAdapter extends Adapter {
  /**
   * Create a new instance of MomentAdapter.
   *
   * @param  {Function}
   */
  constructor (moment) {
    super()
    this.moment = moment
  }

  /**
   * Format a date string like '1986-09-04'.
   *
   * @param  {String}
   * @return {String}
   */
  isoDate (date, utc = false) {
    return this.moment(date).toISOString(!utc).substr(0, 10)
  }

  /**
   * Format a date string like '1986-09-04T08:30:25Z'.
   *
   * @param  {String}
   * @return {String}
   */
  isoDateTime (date, utc = false) {
    return this.moment(date).toISOString(!utc)
  }

  /**
   * Returns a full ISO date-time string for the present moment. Defaults to using UTC.
   *
   * @param  {Boolean}
   * @return {String}
   */
  now (utc = false) {
    return this.moment().toISOString(!utc)
  }

  /**
   * Calculates the number of days from the earlier date to the later date. If the dates are the
   * same then the days until will be 0. If the later date is actually earlier than the early date,
   * then the number of days will be negative.
   *
   * @param  {String}
   * @param  {String}
   * @return {Number}
   */
  numDaysBetween (earlier, later) {
    return this.moment(later).diff(this.moment(earlier), 'day')
  }

  /**
   * Returns 'Today', 'Tomorrow', 'Yesterday', or a calendar date depending on the given date's
   * relation to now.
   *
   * @param  {String}
   * @return {String}
   */
  relativeCalendarDate (date) {
    const dateObj = this.moment(date)

    switch (true) {
      case dateObj.isSame(this.moment().subtract(1, 'day'), 'day'):
        return 'Yesterday'
      case dateObj.isSame(this.moment(), 'day'):
        return 'Today'
      case dateObj.isSame(this.moment().add(1, 'day'), 'day'):
        return 'Tomorrow'
      default:
        return dateObj.format('dddd, LL')
    }
  }

  /**
   * Formats a date string like '9/4/1986'.
   *
   * @param  {String}
   * @return {String}
   */
  shortDate (date) {
    return this.moment(date).format('l')
  }
}
