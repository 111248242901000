export default function lowestRankedTask (state) {
  const rankField = state.migrations?.addRank ? 'rank' : 'createdAt'

  const tasksSorted = state.tasks.sort((a, b) => a[rankField].localeCompare(b[rankField]))

  if (tasksSorted.length) {
    return tasksSorted[tasksSorted.length - 1]
  } else {
    return null
  }
}
