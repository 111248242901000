import container from '@/container'

export default function moveChecklistItemAbove ({ dispatch, getters }, { movedItem, newIndex }) {
  let list

  if (movedItem.parentId === null) {
    list = getters.checklistItemsForChecklist(movedItem.checklistId)
  } else {
    list = getters.checklistItemsForChecklistItem(movedItem.parentId)
  }

  const nextIndex = newIndex
  const prevIndex = nextIndex - 1

  let newRank

  if (prevIndex < 0) {
    // Move all the way to the very top
    newRank = container.make('lexoRank').getRankBetween(
      '',
      list[nextIndex].rank
    )
  } else {
    newRank = container.make('lexoRank').getRankBetween(
      list[prevIndex].rank,
      list[nextIndex].rank
    )
  }

  return dispatch('updateChecklistItem', {
    id: movedItem.id,
    rank: newRank.getValue()
  })
}
