export default class LexoRankNext {
  constructor (lexoRank, prev) {
    this.lexoRank = lexoRank
    this.prev = prev
    this.value = ''

    this.calculate()
  }

  getValue () {
    return this.value
  }

  getNextRank () {
    return this.lexoRank.getNextRank(this.value)
  }

  toJSON () {
    return this.value
  }

  calculate () {
    const currentMax = Math.ceil(this.prev.length / 5) * 5

    if (this.prev.length < currentMax) {
      this.value = this.prev + 'b'
      return
    }

    if (this.prev.length === currentMax) {
      // Do we have all z's yet?
      if (this.prev.replace(/z/g, '') === '') {
        this.value = this.prev + 'ab'
        return
      }
    }

    // At this point we know we need to increment one of the characters in the string
    let index = this.prev.length
    let charCode = 122

    while (index >= 0 && charCode === 122) {
      index -= 1
      charCode = this.prev.charCodeAt(index)
    }

    charCode += 1

    // Part of the prev string before the character we're incrementing +
    // The incremented character
    this.value = this.prev.substr(0, index) + String.fromCharCode(charCode)
  }
}
