<template>
  <dd>
    <template v-if="!hasDueDate && !inEditMode">
      <div class="d-flex align-items-start">
        <span class="flex-fill">No due date</span>
        <b-button @click="toggleEditMode" class="ml-2" size="sm" type="button" variant="light">Add</b-button>
      </div>
    </template>
    <template v-else-if="hasDueDate && !inEditMode">
      <div class="d-flex align-items-start">
        <span class="flex-fill">{{dueDateLabel}}</span>
        <b-button @click="toggleEditMode" class="ml-2" size="sm" type="button" variant="light">Edit</b-button>
      </div>
    </template>
    <template v-else>
      <b-overlay :show="isSaving">
        <form @submit.prevent="save" class="d-flex align-items-start">
          <b-form-datepicker v-model="dueDate" class="flex-fill" />
          <b-button class="ml-2" type="submit" variant="primary">Save</b-button>
          <b-button @click="clear" class="ml-2 text-danger" type="button" variant="link"><fa-icon icon="trash-alt" /></b-button>
        </form>
      </b-overlay>
    </template>
  </dd>
</template>

<script>
import container from '@/container'
import { mapActions } from 'vuex'

export default {
  mounted () {
    this.reset()
  },
  props: {
    task: {
      default: null,
      required: false,
      type: Object
    }
  },
  data () {
    return {
      dueDate: '',
      inEditMode: false,
      isSaving: false
    }
  },
  computed: {
    dueDateLabel () {
      if (this.hasDueDate) {
        return container.make('dateTime').relativeCalendarDate(this.task.dueDate)
      }

      return null
    },
    hasDueDate () {
      return !!this.task?.dueDate
    }
  },
  watch: {
    task () {
      this.reset()
    }
  },
  methods: {
    ...mapActions([
      'updateTask'
    ]),
    clear () {
      this.dueDate = ''
      this.save()
    },
    reset () {
      this.dueDate = this.task?.dueDate || ''
    },
    save () {
      this.isSaving = true

      const task = {
        id: this.task.id,
        dueDate: this.dueDate || null
      }

      this.updateTask(task)
        .then(() => {
          this.inEditMode = false
          this.isSaving = false
        })
    },
    toggleEditMode () {
      this.inEditMode = !this.inEditMode
    }
  }
}
</script>
