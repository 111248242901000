<template>
  <div id="app">
    <!-- NAVBAR -->
    <nav-bar />
    <!-- /NAVBAR -->

    <div id="content-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'

export default {
  name: 'App',
  components: {
    NavBar
  }
}
</script>
