<template>
  <b-overlay :show="isSaving">
    <dl>

      <dt>
        <template v-if="!inEditMode">
          <div class="d-flex align-items-start">
            <span class="flex-fill">{{checklist.title}}</span>
            <b-button @click="inEditMode = true" class="ml-2" size="sm" variant="light">Edit</b-button>
          </div>
        </template>
        <template v-else>
          <form @submit.prevent="handleSubmit" class="d-flex align-items-start">
            <b-form-input v-model="title" class="flex-fill" />
            <b-button class="ml-2" size="sm" type="submit" variant="primary">Save</b-button>
            <b-button @click="handleDelete" class="ml-2 text-danger" size="sm" type="button" variant="link"><fa-icon icon="trash-alt" /></b-button>
          </form>
        </template>
      </dt>

      <dd>
        <b-progress :max="numItems" :value="numCompletedItems" :variant="progressVariant" class="mb-2 mt-2" />
        <draggable
          @change="handleReorder"
          @end="isDragging = false"
          @start="isDragging = true"
          :class="{ 'is-dragging': isDragging }"
          :delay-on-touch-only="true"
          :group="{ name: checklist.id, itemId: null, pull: true, push: true }"
          :value="checklist.items"
          animation="150"
          delay="100"
        >
          <checklist-item v-for="item in checklist.items" @change="handleReorder" :item="item" :key="item.id" />
        </draggable>
        <b-button v-if="!inAddMode" @click="enableAddMode" variant="link">Add item</b-button>
        <div v-else>
          <b-overlay :show="isAdding">
            <b-form @submit.prevent="createChecklistItem" class="d-flex align-items-start">
              <b-form-input v-model="itemTitle" class="flex-fill" ref="itemTitle" />
              <b-button class="ml-2" type="submit" variant="primary">Save</b-button>
              <b-button @click="handleCancelAdd" class="ml-2" type="button" variant="light">Cancel</b-button>
            </b-form>
          </b-overlay>
        </div>
      </dd>

    </dl>
  </b-overlay>
</template>

<script>
import ChecklistItem from './ChecklistItem'
import draggable from 'vuedraggable'
import { mapActions } from 'vuex'

export default {
  components: {
    ChecklistItem,
    draggable
  },
  mounted () {
    this.reset()
  },
  props: {
    checklist: {
      default: null,
      required: false,
      type: Object
    }
  },
  data () {
    return {
      inAddMode: false,
      inEditMode: false,
      isAdding: false,
      isDragging: false,
      isSaving: false,
      itemTitle: '',
      title: ''
    }
  },
  computed: {
    hasItems () {
      return !!this.checklist?.items.length
    },
    numCompletedItems () {
      if (!this.hasItems) {
        return 0
      }

      const walk = (total, item) => {
        let newTotal = total

        if (item.completedOn) {
          newTotal += 1
        }

        if (item.items.length) {
          newTotal = newTotal + item.items.reduce(walk, 0)
        }

        return newTotal
      }

      return this.checklist.items.reduce(walk, 0)
    },
    numItems () {
      if (!this.hasItems) {
        return 0
      }

      const walk = (total, item) => {
        let newTotal = total + 1

        if (item.items.length) {
          newTotal = newTotal + item.items.reduce(walk, 0)
        }

        return newTotal
      }

      return this.checklist.items.reduce(walk, 0)
    },
    progressVariant () {
      return this.numCompletedItems === this.numItems ? 'success' : 'primary'
    }
  },
  watch: {
    'checklist.title' () {
      this.reset()
    }
  },
  methods: {
    ...mapActions([
      'addChecklistItem',
      'deleteChecklist',
      'moveChecklistItemAbove',
      'moveChecklistItemBelow',
      'moveChecklistItemToParent',
      'updateChecklist'
    ]),
    createChecklistItem () {
      this.isAdding = true

      this.addChecklistItem({ checklistId: this.checklist.id, title: this.itemTitle })
        .then(() => {
          this.inAddMode = false
          this.isAdding = false
          this.itemTitle = ''
        })
    },
    enableAddMode () {
      this.inAddMode = true
      this.$nextTick(() => {
        this.$refs.itemTitle.focus()
      })
    },
    handleCancelAdd () {
      this.inAddMode = false
      this.itemTitle = ''
    },
    handleDelete () {
      this.isSaving = true

      this.deleteChecklist(this.checklist.id)
        .then(() => {
          this.inEditMode = false
          this.isSaving = false
        })
    },
    handleReorder (event) {
      let promise

      this.isSaving = true

      if (event.moved) {
        if (event.moved.newIndex < event.moved.oldIndex) {
          promise = this.moveChecklistItemAbove({ movedItem: event.moved.element, newIndex: event.moved.newIndex, oldIndex: event.moved.oldIndex })
        } else {
          promise = this.moveChecklistItemBelow({ movedItem: event.moved.element, newIndex: event.moved.newIndex, oldIndex: event.moved.oldIndex })
        }
      } else if (event.added) {
        promise = this.moveChecklistItemToParent({ movedItem: event.added.element, parentId: event.added.addedTo || null, index: event.added.newIndex })
      }

      if (promise) {
        promise.then(() => {
          this.isSaving = false
        })
      }
    },
    handleSubmit () {
      this.isSaving = true

      this.updateChecklist({ id: this.checklist.id, title: this.title })
        .then(() => {
          this.inEditMode = false
          this.isSaving = false
        })
    },
    reset () {
      this.title = this.checklist?.title
    }
  }
}
</script>
