<template>
  <div>
    <b-overlay :show="isSaving">
      <b-card @click="handleClick" :bg-variant="inEditMode ? 'light' : null" border-variant="0" no-body ref="card">
        <b-card-body class="p-1">
          <template v-if="!inEditMode">
            <b-card-text class="d-flex align-items-start mb-0">
              <b-form-checkbox @change="changeItemStatus" :checked="isComplete" class="ignore-card-click" />
              <span v-if="!isComplete">{{item.title}}</span>
              <span v-else><s>{{item.title}}</s></span>
            </b-card-text>
          </template>
          <template v-else>
            <b-card-text class="mb-0">
              <form @submit.prevent="handleSubmit" class="d-flex align-items-start">
                <b-form-checkbox @change="changeItemStatus" :checked="isComplete" class="ignore-card-click" />
                <b-form-input v-model="title" class="flex-fill" />
                <b-button class="ml-1" type="submit" variant="primary">Save</b-button>
                <b-button @click="handleDelete" class="ml-1 text-danger" type="button" variant="link"><fa-icon icon="trash-alt" /></b-button>
              </form>
            </b-card-text>
          </template>
        </b-card-body>
      </b-card>

    </b-overlay>
    <draggable
      @change="handleReorder"
      @end="isDragging = false"
      @start="isDragging = true"
      :class="{ 'is-dragging': isDragging, 'ml-4': true }"
      :delay-on-touch-only="true"
      :group="{ name: item.checklistId, itemId: item.id, pull: true, push: true }"
      :value="(item.items || [])"
      animation="150"
      delay="100"
    >
      <checklist-item v-for="item in (item.items || [])" @change="handleReorder" :item="item" :key="item.id"  />
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapActions } from 'vuex'

export default {
  name: 'ChecklistItem',
  components: {
    draggable
  },
  mounted () {
    this.reset(this.item.title)
  },
  props: {
    item: {
      default: null,
      required: false,
      type: Object
    }
  },
  data () {
    return {
      inEditMode: false,
      isDragging: false,
      isSaving: false,
      title: ''
    }
  },
  computed: {
    isComplete () {
      return !!this.item.completedOn
    }
  },
  watch: {
    'item.title' () {
      this.reset()
    }
  },
  methods: {
    ...mapActions([
      'completeChecklistItem',
      'deleteChecklistItem',
      'uncompleteChecklistItem',
      'updateChecklistItem'
    ]),
    changeItemStatus () {
      this.isSaving = true

      if (this.isComplete) {
        this.uncompleteChecklistItem(this.item.id)
          .then(() => {
            this.isSaving = false
          })
      } else {
        this.completeChecklistItem(this.item.id)
          .then(() => {
            this.isSaving = false
          })
      }
    },
    handleClick (event) {
      const checkIgnore = (element) => {
        if (element.classList.contains('ignore-card-click')) {
          return true
        } else if (element === this.$refs.card) {
          return false
        }

        return checkIgnore(element.parentElement)
      }

      if (checkIgnore(event.target) === false) {
        this.inEditMode = true
      }
    },
    handleDelete () {
      this.isSaving = true

      this.deleteChecklistItem(this.item.id)
        .then(() => {
          // Theoretically this won't matter
          this.isSaving = false
        })
    },
    handleReorder (event) {
      if (event.added !== undefined && event.added.addedTo === undefined) {
        event.added.addedTo = this.item.id
      }

      this.$emit('change', event)
    },
    handleSubmit () {
      this.isSaving = true

      this.updateChecklistItem({ id: this.item.id, title: this.title })
        .then(() => {
          this.inEditMode = false
          this.isSaving = false
        })
    },
    reset () {
      this.title = this.item?.title || ''
    }
  }
}
</script>
