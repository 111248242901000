import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import { BootstrapVue } from 'bootstrap-vue'
import { FontAwesomeIcon } from './fontAwesome'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import './styles/app.scss'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

Vue.component('fa-icon', FontAwesomeIcon)

Vue.config.productionTip = false

// Wake the page up when visibility is restored
document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    store.dispatch('wakeUp')
  }
})

// Check periodically to see if today has changed to tomorrow
setInterval(() => {
  if (store.state.today.date !== moment().toISOString(true).substr(0, 10)) {
    store.dispatch('wakeUp')
  }
}, 60 * 1000)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
