export default class AddDescription {
  static name = 'addDescription'

  static run (state) {
    state.tasks = (state.tasks || []).map(task => {
      task.description = ''
      return task
    })

    return state
  }
}
