import container from '@/container'

export default async function completeTask ({ dispatch }, taskId) {
  const moment = container.make('moment')

  return dispatch('updateTask', {
    id: taskId,
    completedOn: moment().toISOString(true).substr(0, 10)
  })
}
