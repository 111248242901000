import { cloneDeep } from 'lodash'

export default function checklistForTask (state, getters) {
  return (taskId) => {
    return cloneDeep(state.checklists.filter(item => item.taskId === taskId))
      .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      .map(checklist => {
        checklist.items = getters.checklistItemsForChecklist(checklist.id)
        return checklist
      })
  }
}
