<template>
  <b-container :id="id">
      <b-row align-h="center">
        <b-col md="8">
          <b-overlay :show="isReordering">
            <draggable
              @change="$emit('reorder', $event)"
              @end="isDragging = false"
              @start="isDragging = true"
              :delay-on-touch-only="true"
              :class="{ 'is-dragging': isDragging }"
              :value="tasks"
              animation="150"
              delay="100"
            >
              <task-card
                v-for="task in tasks"
                :key="task.id"
                :currentDate="currentDate"
                :isCarryOver="isCarryOver"
                :task="task"
              />
            </draggable>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
import draggable from 'vuedraggable'
import TaskCard from '@/components/TaskCard'

export default {
  components: {
    draggable,
    TaskCard
  },
  props: {
    currentDate: {
      required: true,
      type: String
    },
    isCarryOver: {
      default: false,
      required: false,
      type: Boolean
    },
    isReordering: {
      default: false,
      required: false,
      type: Boolean
    },
    tasks: {
      required: false,
      type: Array
    }
  },
  data () {
    return {
      isDragging: false
    }
  },
  computed: {
    cmpt () {
      return this.type === 'newTaskList' ? 'NewTaskCard' : 'CarryOverTaskCard'
    },
    id () {
      return this.type === 'newTaskList' ? 'new-tasks-container' : 'carry-over-container'
    }
  }
}
</script>
