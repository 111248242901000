<template>
  <form-group v-bind="{ ...$attrs, ...$props }">
    <b-form-input @input="$emit('input', $event)" :value="value" ref="input" />
  </form-group>
</template>

<script>
import FormGroup from '@/components/FormControls/FormGroup'

export default {
  name: 'FormInput',
  components: {
    FormGroup
  },
  props: {
    value: {
      default: '',
      required: false,
      type: String
    }
  },
  methods: {
    focus () {
      this.$refs.input.focus()
    }
  }
}
</script>
