import container from '@/container'

export default async function moveTaskAbove ({ dispatch, getters }, { newTask, oldTask }) {
  const nextIndex = getters.tasksInOrder.findIndex(item => item.id === oldTask.id)
  const prevIndex = nextIndex - 1

  let newRank

  if (prevIndex < 0) {
    // Moving all the way to the very top
    newRank = container.make('lexoRank').getRankBetween(
      '',
      getters.tasksInOrder[nextIndex].rank
    )
  } else {
    newRank = container.make('lexoRank').getRankBetween(
      getters.tasksInOrder[prevIndex].rank,
      getters.tasksInOrder[nextIndex].rank
    )
  }

  return dispatch('updateTask', {
    id: newTask.id,
    rank: newRank.getValue()
  })
}
