import container from '@/container'

export default async function deleteChecklistItem ({ commit }, checklistItemId) {
  return new Promise((resolve) => {
    setTimeout(() => {
      commit('deleteChecklistItem', checklistItemId)
      resolve(checklistItemId)
    }, container.make('config').saveDelay)
  })

  // return checklistItemId
}
