import container from '@/container'
import Persist from './plugins/Persist'
import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'
import {
  AddRank,
  AddDescription,
  AddDueDate
} from './migrations'

const moment = container.make('moment')

Vue.use(Vuex)

// Set up persistance plugin
const persist = new Persist({
  key: 'task-strike',
  migrations: [
    AddRank,
    AddDescription,
    AddDueDate
  ],
  reducer: (state) => {
    return {
      checklistItems: state.checklistItems,
      checklists: state.checklists,
      migrations: state.migrations,
      tasks: state.tasks
    }
  }
})

// Create store
export default new Vuex.Store({
  state: {
    checklistItems: [],
    checklists: [],
    highlightedTaskId: null,
    tasks: [],
    today: {
      date: moment().toISOString(true).substr(0, 10),
      dateFormatted: moment().format('LL')
    }
  },
  getters: {
    ...getters
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  modules: {
  },
  plugins: [persist.makePlugin()]
})
