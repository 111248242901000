<template>
  <div>
    <b-navbar id="navbar" fixed="top" toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand id="navbar-brand" to="/">TaskStrike</b-navbar-brand>
      <create-task />
    </b-navbar>
  </div>
</template>

<script>
import CreateTask from '@/components/CreateTask'

export default {
  name: 'NavBar',
  components: {
    CreateTask
  }
}
</script>
