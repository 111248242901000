import container from '@/container'
import { prepTask } from '../helpers'

export default async function addTask ({ commit, getters }, task) {
  const moment = container.make('moment')
  const uuid = container.make('uuid')

  const newTask = prepTask(
    task,
    {
      id: uuid(),
      rank: container.make('lexoRank').getNextRank(getters.lowestRank).getValue(),
      startedOn: moment().toISOString(true).substr(0, 10),
      completedOn: null,
      dueDate: null,
      createdAt: moment().toISOString(),
      updatedAt: moment().toISOString()
    }
  )

  return new Promise((resolve) => {
    setTimeout(() => {
      commit('addTask', newTask)
      resolve(newTask)
    }, container.make('config').saveDelay)
  })

  // return newTask
}
