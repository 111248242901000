import container from '@/container'
import { prepChecklist } from '../helpers'

export default function addChecklist ({ commit, getters }, checklist) {
  const uuid = container.make('uuid')

  // Figure out the next number for the checklist
  const regex = /Checklist #\d+/
  const lastNum = getters.checklistsForTask(checklist.taskId)
    .filter(item => regex.test(item.title))
    .map(item => parseInt(item.title.substr(11), 10))
    .reduce((final, current) => final > current ? final : current, 0)

  const newChecklist = prepChecklist(
    checklist,
    {
      id: uuid(),
      title: `Checklist #${lastNum + 1}`,
      createdAt: container.make('dateTime').isoDateTime(null, true),
      updatedAt: container.make('dateTime').isoDateTime(null, true)
    }
  )

  return new Promise((resolve) => {
    setTimeout(() => {
      commit('addChecklist', newChecklist)
      resolve(newChecklist)
    }, container.make('config').saveDelay)
  })

  // return newChecklist
}
