import { cloneDeep } from 'lodash'

export default function taskById (state, getters) {
  return (taskId) => {
    const task = cloneDeep(state.tasks.find(item => item.id === taskId))

    if (!task) {
      return null
    }

    task.checklists = getters.checklistsForTask(task.id)

    return task
  }
}
