import container from '@/container'

export default function moveChecklistItemToParent ({ dispatch, getters }, { movedItem, parentId, index }) {
  let list

  if (parentId === null) {
    list = getters.checklistItemsForChecklist(movedItem.checklistId)
  } else {
    list = getters.checklistItemsForChecklistItem(parentId)
  }

  let newRank
  const lexoRank = container.make('lexoRank')

  if (list.length < 1) {
    // Moved to empty list
    newRank = lexoRank.getNextRank('')
  } else if (index === 0) {
    // Moved to top of non-empty list
    newRank = lexoRank.getRankBetween('', list[index].rank)
  } else if (index === list.length) {
    // Moved to bottom of non-empty list
    newRank = lexoRank.getNextRank(list[index - 1].rank)
  } else {
    // Moved to position previous item was holding
    const prev = list[index - 1] || {}
    const next = list[index] || {}
    newRank = lexoRank.getRankBetween(prev.rank || '', next.rank || '')
  }

  return dispatch('updateChecklistItem', {
    id: movedItem.id,
    parentId: parentId,
    rank: newRank.getValue()
  })
}
