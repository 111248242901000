import container from '@/container'

export default function moveChecklistItemBelow ({ dispatch, getters }, { movedItem, newIndex }) {
  let list

  if (movedItem.parentId === null) {
    list = getters.checklistItemsForChecklist(movedItem.checklistId)
  } else {
    list = getters.checklistItemsForChecklistItem(movedItem.parentId)
  }

  const prevIndex = newIndex
  const nextIndex = prevIndex + 1

  let newRank

  if (nextIndex > list.length - 1) {
    // Move all the way to the very top
    newRank = container.make('lexoRank').getRankBetween(
      list[prevIndex].rank,
      ''
    )
  } else {
    newRank = container.make('lexoRank').getRankBetween(
      list[prevIndex].rank,
      list[nextIndex].rank
    )
  }

  return dispatch('updateChecklistItem', {
    id: movedItem.id,
    rank: newRank.getValue()
  })
}
