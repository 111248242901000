import { cloneDeep } from 'lodash'

export default function tasksForDay (state, getters) {
  return (date) => {
    const filteredTasks = cloneDeep(
      state.tasks.filter(task => {
        if (task.startedOn <= date) {
          return task.startedOn === date || task.completedOn === null || task.completedOn === date
        }

        return false
      })
        .map(task => {
          task.checklists = getters.checklistsForTask(task.id)
          return task
        })
    )

    const rankField = state.migrations && state.migrations.addRank ? 'rank' : 'createdAt'

    return filteredTasks
      .sort((a, b) => a[rankField].localeCompare(b[rankField]))
  }
}
