<template>
  <div id="task-list-container">
    <h1 class="display-4" id="date-header">Task List for {{this.todaysDateFormatted}}</h1>

    <hr />

    <!-- CARRY-OVER -->
    <div>
      <task-list
        v-if="carryOverTasks.length"
        @reorder="reorderCarriedOverTask"
        :currentDate="todaysDate"
        :isReordering="isCarryOverTaskListReordering"
        :tasks="carryOverTasks"
        isCarryOver
      />
      <h3 v-else class="text-center text-shadow">
        No tasks carried over from yesterday.
        <small class="d-block">(🎉 way to defeat procrastination!)</small>
      </h3>
    </div>
    <!-- /CARRY-OVER -->

    <hr />

    <!-- NEW TASKS -->
    <div>
      <task-list
        v-if="newTasks.length"
        @reorder="reorderNewTask"
        :currentDate="todaysDate"
        :isReordering="isNewTaskListReordering"
        :tasks="newTasks"
      />
      <h3 v-else class="text-center text-shadow">
        No tasks added today, yet.
      </h3>
    </div>
    <!-- /NEW TASKS -->

    <!-- TASK-VIEW -->
    <task-details-modal :task="task" />
    <!-- /TASK-VIEW -->
  </div>
</template>

<script>
import TaskDetailsModal from '@/components/TaskDetailsModal'
import TaskList from '@/components/TaskList'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SingleDay',
  components: {
    TaskDetailsModal,
    TaskList
  },
  props: {
    taskId: {
      default: null,
      required: false,
      type: String
    }
  },
  data () {
    return {
      isCarryOverTaskListReordering: false,
      isNewTaskListReordering: false
    }
  },
  computed: {
    ...mapGetters([
      'taskById',
      'tasksForDay',
      'todaysDate',
      'todaysDateFormatted'
    ]),
    task () {
      return this.taskId ? this.taskById(this.taskId) : null
    },
    tasks () {
      return this.tasksForDay(this.todaysDate)
    },
    carryOverTasks () {
      return this.tasks.filter(task => task.startedOn < this.todaysDate)
    },
    newTasks () {
      return this.tasks.filter(task => task.startedOn === this.todaysDate)
    }
  },
  methods: {
    ...mapActions([
      'moveTaskAbove',
      'moveTaskBelow'
    ]),
    reorderCarriedOverTask (event) {
      this.reorderTask(event, 'isCarryOverTaskListReordering', 'carryOverTasks')
    },
    reorderNewTask (event) {
      this.reorderTask(event, 'isNewTaskListReordering', 'newTasks')
    },
    reorderTask (event, reorderFlagName, taskListName) {
      this[reorderFlagName] = true

      let promise

      if (event.moved.newIndex < event.moved.oldIndex) {
        promise = this.moveTaskAbove({ newTask: event.moved.element, oldTask: this[taskListName][event.moved.newIndex] })
      } else {
        promise = this.moveTaskBelow({ newTask: event.moved.element, oldTask: this[taskListName][event.moved.newIndex] })
      }

      promise.then(() => {
        this[reorderFlagName] = false
      })
    }
  }
}
</script>
