export default class LexoRankBetween {
  constructor (lexoRank, prev, next) {
    this.lexoRank = lexoRank
    this.prev = prev
    this.next = next
    this.index = 0
    this.value = ''

    this.calculate()
  }

  getValue () {
    return this.value
  }

  toJSON () {
    return this.value
  }

  /**
   * Adapted from https://stackoverflow.com/questions/38923376/return-a-new-string-that-sorts-between-two-given-strings/38927158#38927158
   */
  calculate () {
    if (this.prev === this.next) {
      throw new Error('Prev and next are equal.')
    }

    // Start at the beginning of the strings and walk them until we find characters that don't
    // match, which will be the index position we want (we're ignoring up to where the string
    // diverge)
    while (this.getPrevCharCode() === this.getNextCharCode()) {
      this.incrementIndex()
    }

    // Let's save the beginning parts of the strings that match before we move on
    this.setValue(this.prev.substr(0, this.index))

    switch (true) {
      case this.getPrevCharCode() === 96:
        this.resolvePrevShorterThanNext()
        break
      case (this.getPrevCharCode() + 1) === this.getNextCharCode():
        this.resolvePrevNextAdjacent()
        break
      default:
        this.addCharBetweenCodesToValue()
        break
    }
  }

  resolvePrevShorterThanNext () {
    // Handle when the prev rank has less characters than the next rank

    // If the next char is an 'a', then add an 'a' and move on to the next letter
    while (this.getNextCharCode() === 97) {
      this.incrementIndex()
      this.addCharToValue('a')
    }

    // If the final char is a 'b', add an 'a' instead and then add the char between the beginning
    // and end of the available characters. This ensures there is space between the new rank and
    // the next rank. Otherwise just add the char between the beginning of the available
    // characters and the next char
    if (this.getNextCharCode() === 98) {
      this.addCharToValue('a')
      this.addCharBetweenCodesToValue(96, 123)
    } else {
      this.addCharBetweenCodesToValue(96, this.getNextCharCode())
    }
  }

  resolvePrevNextAdjacent () {
    // Handle when the next min char would be adjacent to the next max char
    // This is bad because it creates a situation where nothing is in between, like 'aa' and 'aab'
    this.addCharToValue(this.getPrevChar())
    this.incrementIndex()

    // If the next min char is a z: add a z, increment the index, and check again
    while (this.getPrevCharCode() === 122) {
      this.addCharToValue('z')
      this.incrementIndex()
    }

    this.addCharBetweenCodesToValue(this.getPrevCharCode(), 123)
  }

  addCharBetweenCodesToValue (min, max) {
    this.addCharToValue(
      this.getCharBetweenCodes(min || this.getPrevCharCode(), max || this.getNextCharCode())
    )
  }

  addCharToValue (char) {
    this.value += char
  }

  getCharBetweenCodes (min, max) {
    return String.fromCharCode(Math.ceil((min + max) / 2))
  }

  getNextChar () {
    return String.fromCharCode(this.getNextCharCode())
  }

  getNextCharCode () {
    return this.getNextCharCodeAt(this.index)
  }

  getNextCharCodeAt (index) {
    return this.next.length > index ? this.next.charCodeAt(index) : 123
  }

  getPrevChar () {
    return String.fromCharCode(this.getPrevCharCode())
  }

  getPrevCharCode () {
    return this.getPrevCharCodeAt(this.index)
  }

  getPrevCharCodeAt (index) {
    return this.prev.length > index ? this.prev.charCodeAt(index) : 96
  }

  incrementIndex () {
    this.index += 1
  }

  setValue (value) {
    this.value = value
  }
}
