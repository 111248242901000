import { cloneDeep } from 'lodash'

export default function checklistItemsForChecklistItem (state, getters) {
  return (checklistItemId) => {
    return cloneDeep(state.checklistItems.filter(item => item.parentId === checklistItemId))
      .map(item => {
        item.items = getters.checklistItemsForChecklistItem(item.id)
        return item
      })
      .sort((a, b) => a.rank.localeCompare(b.rank))
  }
}
