<template>
  <dd>
    <template v-if="!inEditMode && !hasDescription">
      <div class="d-flex align-items-start">
        <span class="flex-fill">No description</span>
        <b-button @click="toggleEditMode" class="ml-2" size="sm" type="button" variant="light">Add</b-button>
      </div>
    </template>
    <template v-else-if="!inEditMode">
      <div class="d-flex align-items-start">
        <span v-html="descriptionHtml" class="flex-fill markdown" ref="descriptionHtml" />
        <b-button @click="toggleEditMode" class="ml-2" size="sm" type="button" variant="light">Edit</b-button>
      </div>
    </template>
    <template v-else>
      <b-overlay :show="isSaving">
        <b-form @submit.prevent="save">
          <b-form-textarea v-model="description" ref="textarea"></b-form-textarea>
          <b-form-text>Supports <a href="https://www.markdownguide.org/cheat-sheet/#basic-syntax" target="_blank">basic Markdown syntax</a>, tables, code blocks, and strikethrough</b-form-text>
          <b-button block class="mt-2" type="submit" variant="primary">Save</b-button>
        </b-form>
      </b-overlay>
    </template>
  </dd>
</template>

<script>
import container from '@/container'
import { mapActions } from 'vuex'

export default {
  mounted () {
    this.reset()

    this.$nextTick(() => {
      this.adjustLinkTargets()
    })
  },
  props: {
    task: {
      default: null,
      required: false,
      type: Object
    }
  },
  data () {
    return {
      inEditMode: false,
      isSaving: false,
      description: ''
    }
  },
  computed: {
    hasDescription () {
      return !!this.description.length
    },
    descriptionHtml () {
      const markdownIt = container.make('markdownIt')
      return markdownIt.render(this.description)
    }
  },
  watch: {
    description () {
      this.adjustTextAreaHeight()
    },
    task () {
      this.reset()
    }
  },
  methods: {
    ...mapActions([
      'updateTask'
    ]),
    adjustLinkTargets () {
      if (this.$refs.descriptionHtml) {
        this.$refs.descriptionHtml.querySelectorAll('a').forEach(link => {
          link.target = '_blank'
        })
      }
    },
    adjustTextAreaHeight () {
      if (this.$refs.textarea) {
        this.$refs.textarea.$el.style.height = 'auto'
        this.$refs.textarea.$el.style.height = this.$refs.textarea.$el.scrollHeight + 'px'
      }
    },
    reset () {
      this.description = this.task?.description || ''
    },
    save () {
      this.isSaving = true

      const task = {
        id: this.task.id,
        description: this.description
      }

      return this.updateTask(task)
        .then(() => {
          this.toggleEditMode(false)
          this.isSaving = false
        })
    },
    toggleEditMode (state) {
      this.inEditMode = state !== undefined ? !!state : !this.inEditMode

      this.$nextTick(() => {
        if (this.inEditMode) {
          this.adjustTextAreaHeight()
        }

        this.adjustLinkTargets()
      })
    }
  }
}
</script>
