<template>
  <div class="d-inline">
    <b-button @click="openModal" size="sm" variant="outline-success">
      <fa-icon fixedWidth icon="plus" />
      Create Task
    </b-button>
    <b-modal @shown="modalShown" :visible="isModalOpen" hide-header-close no-close-on-backdrop no-close-on-esc title="Create Task">
      <form @submit.prevent="submitForm">
        <form-input v-model="task.title" label="Task Title" ref="title-input" />
        <b-button :disabled="isProcessing" class="d-none" type="submit" />
      </form>
      <template slot="modal-footer">
        <b-button @click="closeModal" :disabled="isProcessing" variant="secondary">Cancel</b-button>
        <b-button @click="submitForm" :disabled="isProcessing" variant="primary">Create</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { FormInput } from '@/components/FormControls'
import { mapActions, mapMutations } from 'vuex'

export default {
  components: {
    FormInput
  },
  data () {
    return {
      isModalOpen: false,
      isProcessing: false,
      task: {
        title: ''
      }
    }
  },
  methods: {
    ...mapActions([
      'addTask'
    ]),
    ...mapMutations([
      'highlightTask'
    ]),
    closeModal () {
      this.resetTask()
      this.isModalOpen = false
    },
    modalShown () {
      this.$refs['title-input'].focus()
    },
    openModal () {
      this.resetTask()
      this.isModalOpen = true
    },
    resetTask () {
      this.task = {
        title: ''
      }
    },
    submitForm () {
      // Prevent multiple submissions
      if (this.isProcessing) {
        return
      }

      this.isProcessing = true

      this.addTask(this.task)
        .then(task => {
          this.closeModal()
          this.isProcessing = false

          this.$root.$once('bv::modal::hidden', () => {
            // Next-tick is used just to be sure the modal has fully hidden
            this.$nextTick(() => {
              this.highlightTask(task.id)
            })
          })
        })
    }
  }
}
</script>
