import container from '@/container'
import { prepChecklistItem } from '@/store/helpers'

export default function addChecklistItem ({ commit, getters }, data) {
  const checklistItem = prepChecklistItem(
    data,
    {
      id: container.make('uuid')(),
      parentId: null,
      rank: container.make('lexoRank')
        .getNextRank(getters.lowestChecklistItemRankForChecklist(data.checklistId))
        .getValue(),
      completedOn: null,
      createdAt: container.make('dateTime').isoDateTime(null, true),
      updatedAt: container.make('dateTime').isoDateTime(null, true)
    }
  )

  return new Promise((resolve) => {
    setTimeout(() => {
      commit('addChecklistItem', checklistItem)
      resolve(checklistItem)
    }, container.make('config').saveDelay)
  })

  // return checklistItem
}
