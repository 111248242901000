export default class Adapter {
  /**
   * Formats a date string like 'Sep 4, 1986'.
   *
   * @param  {String}
   * @return {String}
   */
  abbrevDate (date) {
    throw new Error(`${this.constructor.name}::abbrevDate() has not been implemented.`)
  }

  /**
   * Formats a date string like 'Sep 4, 1986 8:30 PM'.
   *
   * @param  {String}
   * @return {String}
   */
  abbrevDateTime (date) {
    throw new Error(`${this.constructor.name}::abbrevDateTime() has not been implemented.`)
  }

  /**
   * Formats a date string like 'Thu, Sep 4, 1986 8:30 PM'.
   *
   * @param  {String}
   * @return {String}
   */
  abbrevDayDateTime (date) {
    throw new Error(`${this.constructor.name}::abbrevDayDateTime() has not been implemented.`)
  }

  /**
   * Returns a date string like, 'Thursday, September 4, 1986'.
   *
   * @param  {String}
   * @return {String}
   */
  calendarDate (date) {
    throw new Error(`${this.constructor.name}::calendarDate() has not been implemented.`)
  }

  /**
   * Formats a date string like 'September 4, 1986'.
   *
   * @param  {String}
   * @return {String}
   */
  fullDate (date) {
    throw new Error(`${this.constructor.name}::fullDate() has not been implemented.`)
  }

  /**
   * Formats a date string like 'September 4, 1986 8:30 PM'.
   *
   * @param  {String}
   * @return {String}
   */
  fullDateTime (date) {
    throw new Error(`${this.constructor.name}::fullDateTime() has not been implemented.`)
  }

  /**
   * Formats a date string like 'Thursday, September 4, 1986 8:30 PM'.
   *
   * @param  {String}
   * @return {String}
   */
  fullDayDateTime (date) {
    throw new Error(`${this.constructor.name}::fullDayDateTime() has not been implemented.`)
  }

  /**
   * Formats a date string like '09/04/1986'.
   *
   * @param  {String}
   * @return {String}
   */
  fullShortDate (date) {
    throw new Error(`${this.constructor.name}::fullShortDate() has not been implemented.`)
  }

  /**
   * Formats a date string like '8:30:25 PM'.
   *
   * @param  {String}
   * @return {String}
   */
  fullTime (date) {
    throw new Error(`${this.constructor.name}::fullTime() has not been implemented.`)
  }

  /**
   * Format a date string like '1986-09-04'.
   *
   * @param  {String}
   * @return {String}
   */
  isoDate (date, utc = false) {
    throw new Error(`${this.constructor.name}::isoDate() has not been implemented.`)
  }

  /**
   * Format a date string like '1986-09-04T08:30:25Z'.
   *
   * @param  {String}
   * @return {String}
   */
  isoDateTime (date, utc = false) {
    throw new Error(`${this.constructor.name}::isoDateTime() has not been implemented.`)
  }

  /**
   * Format a date string like '08:30:25Z'.
   *
   * @param  {String}
   * @return {String}
   */
  isoTime (date) {
    throw new Error(`${this.constructor.name}::isoTime() has not been implemented.`)
  }

  /**
   * Returns a full ISO date-time string for the present moment. Defaults to using UTC.
   *
   * @param  {Boolean}
   * @return {String}
   */
  now (utc = false) {
    throw new Error(`${this.constructor.name}::now() has not been implemented.`)
  }

  /**
   * Calculates the number of days from the earlier date to the later date. If the dates are the
   * same then the days until will be 0. If the later date is actually earlier than the early date,
   * then the number of days will be negative.
   *
   * @param  {String}
   * @param  {String}
   * @return {Number}
   */
  numDaysBetween (earlier, later) {
    throw new Error(`${this.constructor.name}::numDaysBetween() has not been implemented.`)
  }

  /**
   * Returns 'Today', 'Tomorrow', 'Yesterday', or a calendar date depending on the given date's
   * relation to now.
   *
   * @param  {String}
   * @return {String}
   */
  relativeCalendarDate (date) {
    throw new Error(`${this.constructor.name}::relativeCalendarDate() has not been implemented.`)
  }

  /**
   * Formats a date string like '9/4/1986'.
   *
   * @param  {String}
   * @return {String}
   */
  shortDate (date) {
    throw new Error(`${this.constructor.name}::shortDate() has not been implemented.`)
  }

  /**
   * Formats a date string like '8:30 PM'.
   *
   * @param  {String}
   * @return {String}
   */
  time (date) {
    throw new Error(`${this.constructor.name}::time() has not been implemented.`)
  }
}
