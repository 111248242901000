export default class AddDueDate {
  static name = 'addDueDate'

  static run (state) {
    state.tasks = (state.tasks || []).map(task => {
      task.dueDate = null
      return task
    })

    return state
  }
}
