<template>
  <b-modal @hide="hideModal" :visible="!!task" hide-footer size="lg" title="Task Details">
    <dl>
      <dt>Title</dt>
      <title-row :task="task" />

      <dt><fa-icon class="mr-1" fixed-width icon="align-left" /> Description</dt>
      <description-row :task="task" />

      <dt><fa-icon class="mr-1" fixed-width :icon="['far', 'calendar-alt']" /> Due Date</dt>
      <due-date-row :task="task" />

      <dt><fa-icon class="mr-1" fixed-width icon="tasks" /> Checklists</dt>
      <checklists-row :task="task" />
    </dl>
  </b-modal>
</template>

<script>
import ChecklistsRow from './ChecklistsRow'
import DescriptionRow from './DescriptionRow'
import DueDateRow from './DueDateRow'
import TitleRow from './TitleRow'

export default {
  components: {
    ChecklistsRow,
    DescriptionRow,
    DueDateRow,
    TitleRow
  },
  props: {
    task: {
      default: null,
      required: false,
      type: Object
    }
  },
  methods: {
    hideModal (event) {
      if (this.task) {
        event.preventDefault()
        this.$router.push('/')
      }
    }
  }
}
</script>
