import container from '@/container'
import { prepTask } from '../helpers'

export default async function ({ commit, getters }, task) {
  const oldTask = getters.taskById(task.id) || {}

  const newTask = prepTask(oldTask, task, true)

  return new Promise((resolve) => {
    setTimeout(() => {
      commit('updateTask', newTask)
      resolve(newTask)
    }, container.make('config').saveDelay)
  })

  // return newTask
}
