import merge from 'deepmerge'
import { cloneDeep } from 'lodash'

export default class Persist {
  constructor ({ key, mergeOption, migrations = [], reducer }) {
    this.key = key
    this.mergeOption = mergeOption || 'replaceArrays'
    this.migrations = migrations
    this.reducer = reducer
  }

  addMigrations (...migrations) {
    this.migrations = [...this.migration, ...migrations]
  }

  makePlugin () {
    return async (store) => {
      // Set up persisting data in storage
      store.subscribe((mutation, state) => {
        const value = JSON.stringify(this.reducer(state))
        localStorage.setItem(this.key, value)
      })

      // Restore data from storage
      const loadedState = this.loadStateFromStorage()
      const migratedState = await this.migrateState(loadedState, store)

      store.commit('initialize', merge(store.state, migratedState || {}, this.getMergeOption))
    }
  }

  getMergeOption () {
    const mergeOptions = {
      replaceArrays: {
        arrayMerge: (destinationArray, sourceArray, options) => sourceArray
      },
      concatArrays: {
        arrayMerge: (target, source, options) => target.concat(...source)
      }
    }

    return mergeOptions[this.mergeOption]
  }

  loadStateFromStorage () {
    const value = localStorage.getItem(this.key)

    if (typeof value === 'string') {
      return JSON.parse(value || '{}')
    } else {
      return (value || {})
    }
  }

  async migrateState (state, store) {
    let migratedState = cloneDeep(state)

    if (migratedState.migrations === undefined) {
      migratedState.migrations = {}
    }

    for (const migration of this.migrations) {
      if (migratedState.migrations[migration.name] !== true) {
        migratedState = await migration.run(cloneDeep(migratedState), store)
        migratedState.migrations[migration.name] = true
      }
    }

    return migratedState
  }
}
