export default function lowestRankedChecklistItemForChecklist (state, getters) {
  return (checklistId) => {
    const items = getters.checklistItemsForChecklist(checklistId)

    if (!items.length) {
      return null
    } else {
      return items[items.length - 1]
    }
  }
}
