import container from '@/container'
import { cloneDeep } from 'lodash'

export default async function wakeUp ({ commit, state }) {
  const moment = container.make('moment')

  if (state.today.date === moment().toISOString(true).substr(0, 10)) {
    return cloneDeep(state.today)
  }

  const today = {
    date: moment().toISOString(true).substr(0, 10),
    dateFormatted: moment().format('LL')
  }

  commit('updateToday', today)

  return cloneDeep(today)
}
