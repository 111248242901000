import MarkdownIt from 'markdown-it'

export default function (container) {
  container.singleton('markdownIt', () => {
    return new MarkdownIt({
      html: false,
      linkify: true
    })
  })
}
