import container from '@/container'

export default class AddRank {
  static name = 'addRank'

  static run (state) {
    const tasksInOrder = Object.values(state.tasks || [])
      .sort((a, b) => a.createdAt.localeCompare(b.createdAt))

    const lexoRank = container.make('lexoRank')
    let nextRank = lexoRank.getNextRank('')

    state.tasks = tasksInOrder.map(task => {
      task.rank = nextRank.getValue()
      nextRank = nextRank.getNextRank()
      return task
    })

    return state
  }
}
