import dateTimeProvider from '@/providers/dateTimeProvider'
import lexoRankProvider from '@/providers/lexoRankProvider'
import markdownItProvider from '@/providers/markdownItProvider'
import momentProvider from '@/providers/momentProvider'
import uuidProvider from '@/providers/uuidProvider'
import { Container } from '@halliganjs/service-container'

const container = new Container()

container.provider(dateTimeProvider)
  .provider(lexoRankProvider)
  .provider(markdownItProvider)
  .provider(momentProvider)
  .provider(uuidProvider)

container.instance('config', {
  saveDelay: process && process.env && process.env.NODE_ENV !== 'test' ? 500 : 0
})

export default container
