import container from '@/container'
import { prepChecklist } from '../helpers'

export default function updateChecklist ({ commit, getters }, checklist) {
  const oldChecklist = getters.checklistById(checklist.id) || {}

  const newChecklist = prepChecklist(oldChecklist, checklist, true)

  return new Promise((resolve) => {
    setTimeout(() => {
      commit('updateChecklist', newChecklist)
      resolve(newChecklist)
    }, container.make('config').saveDelay)
  })

  // return new
}
