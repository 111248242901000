import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCalendarAlt
} from '@fortawesome/free-regular-svg-icons'
import {
  faAlignLeft,
  faPlus,
  faTasks,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faAlignLeft,
  faCalendarAlt,
  faPlus,
  faTasks,
  faTrashAlt
)

export { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
