import container from '@/container'
import { cloneDeep, pick } from 'lodash'

/**
 * Takes a base checklist and merges updates on top of it, returning a deep copy of the result.
 *
 * @param  {Object}
 * @param  {Object}
 * @param  {Boolean}
 * @return {Object}
 */
export function prepChecklist (baseChecklist, nextChecklist, isUpdate = false) {
  const newChecklist = cloneDeep({ ...baseChecklist, ...nextChecklist })

  newChecklist.title = newChecklist.title ? newChecklist.title.trim() : ''

  if (isUpdate) {
    newChecklist.updatedAt = container.make('dateTime').isoDateTime(null, true)
  }

  return pick(newChecklist, [
    'id',
    'taskId',
    'title',
    'createdAt',
    'updatedAt'
  ])
}

/**
 * Takes a base checklist item and merges updates on top of it, returning a deep copy of the result.
 *
 * @param  {Object}
 * @param  {Object}
 * @param  {Boolean}
 * @return {Object}
 */
export function prepChecklistItem (baseChecklistItem, nextChecklistItem, isUpdate = false) {
  const dateTime = container.make('dateTime')
  const newChecklistItem = cloneDeep({ ...baseChecklistItem, ...nextChecklistItem })

  newChecklistItem.title = newChecklistItem.title ? newChecklistItem.title.trim() : ''

  if (isUpdate) {
    newChecklistItem.updatedAt = dateTime.isoDateTime(null, true)
  }

  return pick(newChecklistItem, [
    'id',
    'checklistId',
    'parentId',
    'title',
    'rank',
    'completedOn',
    'createdAt',
    'updatedAt'
  ])
}

/**
 * Takes a base task and merges updates on top of it, returning a deep copy of the result.
 *
 * @param  {Object}
 * @param  {Object}
 * @param  {Boolean}
 * @return {Object}
 */
export function prepTask (baseTask, nextTask, isUpdate = false) {
  const dateTime = container.make('dateTime')
  const newTask = cloneDeep({ ...baseTask, ...nextTask })

  newTask.title = newTask.title ? newTask.title.trim() : ''
  newTask.description = newTask.description ? newTask.description.trim() : ''

  if (isUpdate) {
    newTask.updatedAt = dateTime.isoDateTime(null, true)
  }

  return pick(newTask, [
    'id',
    'title',
    'description',
    'rank',
    'startedOn',
    'completedOn',
    'dueDate',
    'createdAt',
    'updatedAt'
  ])
}
