<template>
  <dd>
    <checklist v-for="checklist in checklists" :checklist="checklist" :key="`${checklist.id}-checklist`" />
    <b-button @click="handleAddChecklist" :disabled="isAdding" block variant="light">Add Checklist</b-button>
  </dd>
</template>

<script>
import Checklist from './Checklist'
import { mapActions } from 'vuex'

export default {
  components: {
    Checklist
  },
  props: {
    task: {
      default: null,
      required: false,
      type: Object
    }
  },
  data () {
    return {
      isAdding: false
    }
  },
  computed: {
    checklists () {
      return this.task?.checklists || []
    }
  },
  methods: {
    ...mapActions([
      'addChecklist'
    ]),
    handleAddChecklist () {
      this.isAdding = true

      this.addChecklist({ taskId: this.task.id })
        .then(() => {
          this.isAdding = false
        })
    }
  }
}
</script>
